<template>
<div class="user-page">
  <div class="header" v-if="$route.path != '/user/login'">
    <div class="content">
      <el-image @click="index" class="logo" :src="require('../../assets/index/logo_index.png')" fit="cover"></el-image>
      <el-link v-if="$route.path == '/user/forget'" style="flex: auto;text-align: right;padding-right:50px;" @click="index" :underline="false" type="primary">返回登录</el-link>
      <el-dropdown v-if="name" class="user" @command="logout">
        <div class="user-wrap">
          <el-avatar class="avatar" :size="38" fit="cover" :src="circleUrl" @error="errorHandler" placement="bottom">
            <img style="width: 100%; height: 100%" :src="require('../../assets/index/avatar.png')" alt="" /></el-avatar>
          <span>{{name}}</span>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="c"><span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
  <router-view />
  <Footer v-if="$route.path != '/user/login'"/>
  <el-dialog title="用户操作视频" :visible.sync="dialogVisible" @close="dialogClose" width="70%">
    <video class="video" controls v-if="dialogVideoUrl" width="100%" :src="dialogVideoUrl" alt="" />
  </el-dialog>
</div>
</template>

<script>
import Footer from "@/components/Footer.vue";

export default {
  name: "Login",
  data() {
    return {
      name: '',
      dialogTitle: '',
      dialogVisible: false,
      dialogVideoUrl: 'dialogVideoUrl',
    };
  },
  computed: {

  },
  components: {
    Footer
  },
  mounted() {
    this.name = localStorage.getItem("name");
  },
  methods: {
    index() {
      this.$router.push("/")
    },
    logout(e) {
      if (e == 'c') {
        localStorage.clear();
        this.name = '';
        this.$router.push("/user/login");
      } else if (e == 'a') {
        this.$router.push('/user/organ/check')
      }
    },
    dialogClose() {
      this.dialogVisible = false;
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.name = localStorage.getItem("name");
    next()
  },
};
</script>

<style lang="scss">
.user-page {
  min-height: 100vh;

  .el-link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 16px;
  }

  .header {
    width: 100vw;
    height: 124px;
    background: #ffffff;
    box-shadow: 0px 6px 13px 0px rgba(22, 127, 204, 0.13);
    font-size: 16px;

    .user {
      flex: 0 0 160px;

      .user-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        cursor: pointer;
      }

      .avatar {
        margin-right: 12px;
      }
    }

    .content {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 1240px;
      height: 100%;

      .logo {
        width: 418px;
      }

      .pdf {
        display: flex;
        align-items: center;

        a,
        span {
          color: #000;
          text-decoration: none;
        }

        span {
          display: inline-block;
          height: 24px;
          background: #d2d2d2;
          width: 1px;
          margin: 0 22px;
        }

        a:hover {
          color: #333;
        }
      }
    }
  }

  .login-main {

    /* 主题色按钮 */
    .el-button--primary {
      background-color: #0083f5;
      border-color: #0083f5;
      height: 46px;
      border-radius: 6px;
      letter-spacing: 8px;
      font-size: 16px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .el-button--primary.is-disabled,
    .el-button--primary.is-disabled:active,
    .el-button--primary.is-disabled:focus,
    .el-button--primary.is-disabled:hover {
      color: #FFF;
      background-color: #a0cfff;
      border-color: #a0cfff;
    }

    /* 全局input */
    .el-input__inner {
      height: 48px;
      border-radius: none;
      font-size: 14px;
      border: none;
      height: 42px;
      background: #f3f4f9;
      padding: 0 20px;
    }

    .el-form-item__content {
      display: flex;
      line-height: 42px;
    }

    .el-loading-spinner .el-icon-loading {
      font-size: 30px;
      color: #fff;
    }

    .el-loading-spinner .el-loading-text {
      font-size: 18px;
      color: #fff;
    }
  }

}

@media only screen and (max-width: 1440px) {
  $zoom: 0.75;

  .user-page {
    min-height: 100vh;

    .login-main {
      .el-button--primary {
        height: calc(46px * 0.75);
        border-radius: 4px;
        letter-spacing: 4px;
        font-size: 14px;
      }

      .el-form-item__content {
        line-height: calc(42px * 0.75);
      }

      .el-form-item {
        margin-bottom: calc(22px * 0.75);
      }
    }

    .header {
      height: calc(124px * $zoom);

      .content {
        width: 960px;

        .logo {
          width: calc(418px * $zoom);
        }

        .pdf {
          span {
            display: inline-block;
            height: calc(24px * $zoom);
            margin: 0 calc(22px * $zoom);
          }
        }
      }
    }
  }
}
</style>
